
body{
  padding:0px;
}

.App {
  text-align: center;
  background-color: #999696;
  padding:10px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  margin: 0 auto;
  min-height: 800px;

}
.header{
  background-color:black;
  width:84%;
  padding:5px;
  border-radius: 4px 4px 0 0;
  margin: 0 auto;
  text-align: right;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.toolbar{
  background-color:black;
  width:99%;
  padding:5px;
  border-radius: 4px;

  text-align: right;
}
.toolbar .left{
  display:block;
  margin-left:0px;
  float:left;
  color:white;
  font-size:20px;
  font-weight:bold;
  padding:5px;
}
.toolbar .right{
  display:block;
  margin-right:0px;
  
  
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.feedItems{
  background-color:#fff;
  width:100%;
  padding:5px;
  text-align: left;
}
.feedItems div{
  font-size:20px;
  font-weight:bold;
  color:black;
  padding:0px;
  text-align: left;
}
.feedItems .feedItemText{
  font-size:15px;
  font-weight:bold;
  color:black;
  padding:10px;
  text-align: left;
}
.AddFeedItem{
  float:right;
  font-size:1.0em;
  top:-75px;
  height:2.5em;
  
  
   
}
.modal .newFeedcontent{
  width:100%;
  height:8rem;
  height:9em
  
}
.newFeedItemText{
  color:black;
  font-size:20px;
  font-weight:bold;
  padding:0px;
  text-align: left;
  height:250px;
  
}

.addFeedItemIcon{
  color:green
}

.newFeedItemText{
  width:95%;
  height:8rem;
  margin:5px;
}
.PageTitle{
  font-size:30px;
  font-weight:bold;
  color:black;
  padding:10px;
  text-align: left;
}
.navigation{
  background-color:rgb(100, 100, 100);
  width:99%;
  padding:5px;
  text-align: center;
  border-radius: 4px;
}
.navlist{
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: rgb(100, 100, 100);
  display: flex;
  justify-content: space-between;
}
.navlist ul{
  list-style-type: none;
  margin: 0;
  padding: 0px;
  overflow: hidden;
  background-color: rgb(100, 100, 100);
  display: flex;
  justify-content: space-between;
}
.navlist li{
  float: left;
  padding: 5px;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
}
.navlist li a{
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 15px;
  font-weight: bold;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.content{
  width:84%;
  margin: 0 auto;
  padding:10px;
  background-color:white;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  min-height: 600px;

  
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.modal {
  position:fixed;
  
  transform: translate(-50%, -50%);
  top: 20%;
  left: 35%;

  width: 500px;
  background: white;
  border: 1px solid #ccc;
  transition: 1.1s ease-out;
  box-shadow: -2rem 2rem 2rem rgba(black, 0.2);
  filter: blur(0);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
  &.red {
    background: black;
    color: red;
  }
  &.blue {
    background: blue;
    color: red;
  }

  &.off {
    opacity: 0;
    visibility: hidden;
    filter: blur(8px);
    transform: scale(0.33);
    box-shadow: 1rem 0 0 rgba(black, 0.2);
  }
  @supports (offset-rotate: 0deg) {
   
    offset-rotate: 0deg;
    offset-path: path("M 250,100 S -300,500 -700,-200");
    &.off {
      offset-distance: 100%;
    }
  }
  @media (prefers-reduced-motion) {
    offset-path: none;
  }
 
  
  .actions {
    border-top: 1px solid #ccc;
    background-color: lightgray;
    padding: 0.5rem 1rem;
    
   
  }
}
.modal .modalcontent {
  padding: 1rem;


}
.modal  h2 {
  border-bottom: 1px solid #ccc;
  padding: .5rem;
  margin: 0;
  background-color:lightgray;
}
.actions button {
  border: 0;
  background: #fff;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  font-size:1em;
  line-height: 1;
}
.modalButtonYes{
  color:green;
  
}
.modalButtonNo{
  color:red;
}
.FeedLink{
  
  
  padding:5px;
  text-align: left;
}
.FeedLink h3 a{
  color:black;
  font-size:20px;
  font-weight:bold;
  padding:0px;
  text-align: left;
  text-decoration:none;
  cursor:pointer
}
.FeedLink h3 a:hover{
  text-decoration:underline;
}
.FeedCard{
  width:60%;
}