.FeedCard{
    width: 70%;
    height: 100%;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease-in-out;
    margin-bottom:15px;

}
.FeedCard p{
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
}