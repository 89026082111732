body {
    background-color: #f0f0f0;
    font-family: Arial, sans-serif;
  }
  
  div.signupin {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  }
  
  .signupin input {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-sizing: border-box;
  }
  
  .signupin button {
    display: block;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: none;
    background-color: #007BFF;
    color: #ffffff;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .signupin button:hover {
    background-color: #0056b3;
  }
  